window.addEventListener("load", function () {

    var breakpoints = {
        lg: 1200,
        md: 992,
        sm: 768,
        xs: 0
    };

    var windowWidth = window.innerWidth;

    function getBreakpointSize() {
        for (var i = 0; i < breakpoints.length; i++) {
            if (breakpoints[i] < windowWidth) {
                return breakpoints[i].key;
            }
        }

        return breakpoints;
    }

    function constructor() {
        initDomElements();
        render();
    }

    function initDomElements() {
        window.$sidebar = $('.nav-sidebar');
        if (!window.$sidebar.length) return;

        window.$innerScroll = $('.nav-sidebar-inner-scroll', window.$sidebar);
        window.$overlay = $('.mobile-overlay');
        window.$openSidebar = $('.open-mobile-nav');
        window.$closeSidebar = $('.close-nav-button');
        window.$sidebarToggle = $('.toggle-mobile-nav');
    }

    function bindEvents() {
        if (!window.$sidebar.length) return;


        $openSidebar.on('click', function () {
            toggleSidebarNav(true)
        });
        $closeSidebar.on('click', function () {
            toggleSidebarNav(false)
        });
        $overlay.on('click', function () {
            toggleSidebarNav(false)
        });
        $sidebarToggle.on('click', function () {
            var value = !$sidebar.hasClass('sidebar-expanded-mobile');
            toggleSidebarNav(value);
        });

        $(window).on('resize', debounce(render(), 100));
    }

    function setCollapsedCookie(value) {
        if (getBreakpointSize() !== 'lg') {
            return;
        }
        Cookies.set('sidebar_collapsed', value, { expires: 365 * 10 });
    }

    function toggleSidebarNav(show) {
        window.$sidebar.toggleClass('sidebar-expanded-mobile', show);
        window.$overlay.toggleClass('mobile-nav-open', show);
        window.$sidebar.removeClass('sidebar-collapsed-desktop');
        $("body").toggleClass('modal-open', show);
    }

    function toggleCollapsedSidebar(collapsed, saveCookie) {
        var breakpoint = getBreakpointSize();

        if (window.$sidebar.length) {
            window.$sidebar.toggleClass('sidebar-collapsed-desktop', collapsed);
        }

        if (saveCookie) {
            ContextualSidebar.setCollapsedCookie(collapsed);
        }

        requestIdleCallback(toggleSidebarOverflow());
    }

    function toggleSidebarOverflow() {
        if (window.$innerScroll.prop('scrollHeight') > window.$innerScroll.prop('offsetHeight')) {
            window.$innerScroll.css('overflow-y', 'scroll');
        } else {
            window.$innerScroll.css('overflow-y', '');
        }
    }

    function render() {
        if (!window.$sidebar.length) return;

        var breakpoint = getBreakpointSize();

        if (breakpoint === 'sm' || breakpoint === 'md') {
            window.toggleCollapsedSidebar(true, false);
        } else if (breakpoint === 'lg') {
            var collapse = parseBoolean(Utils.Cookie.get('sidebar_collapsed'));
            window.toggleCollapsedSidebar(collapse, false);
        }
    }

    function parseBoolean(string) {
        var bool;
        string = string || "";

        bool = (function () {
            switch (false) {
                case string.toLowerCase() !== 'true':
                    return true;
                case string.toLowerCase() !== 'false':
                    return false;
            }
        })();
        if (typeof bool === "boolean") {
            return bool;
        }
        return void 0;
    }

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = window, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    constructor();
    bindEvents();

    //collapse all items but the one clicked at the sub level
    $(".sidebar-sub-level-items > li > a.activeToggle").click(function () {
        var currentItem = $(this).html();
        $(".sidebar-sub-level-items > li > a.activeToggle").each(function () {
            //if it was the clicked item, ignore it
            if (currentItem !== $(this).html()) {
                $(this).parent("li").removeClass("active");
            }
        });

        $(this).parent("li").toggleClass("active");
    });

    //collapse all items but the one clicked at the top level 
    $(".sidebar-top-level-items > li > a.activeToggle").click(function () {
        var currentItem = $(this).html();
        $(".sidebar-top-level-items > li > a.activeToggle").each(function () {
            //if it was the clicked item, ignore it
            if (currentItem !== $(this).html()) {
                $(this).parent("li").removeClass("active");
            }

        });

        $(this).parent("li").toggleClass("active");
    });
});